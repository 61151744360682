import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucService extends BaseService{
    list: ToChuc[] | undefined;
    list001: ToChuc[] | undefined;
    formData!: ToChuc;    

    list0001: ToChuc[] | undefined;

    list0002: ToChuc[] | undefined;

    list1001: ToChuc[] | undefined;
    list1002: ToChuc[] | undefined;
    list1003: ToChuc[] | undefined;

    displayColumns001: string[] = ['STT', 'Code', 'Name', 'DienThoai', 'DienTichSanXuatHa', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName'];   
    displayColumns002: string[] = ['STT', 'Name', 'DienThoai', 'Email', 'DiaChi', 'Save'];   
    displayColumns003: string[] = ['STT', 'DanhMucTinhThanhID', 'Name', 'DienThoai', 'Email', 'DiaChi', 'Description', 'Save'];   
    displayColumns004: string[] = ['STT', 'Name', 'DienThoai', 'DienTichSanXuatHa', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'Save'];   
    displayColumns005: string[] = ['STT', 'Name', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'Save', 'ID'];
    constructor(        
        public httpClient: HttpClient
    ) {
        super(httpClient);
        this.controller = "ToChuc";
    }
    GetByErrorToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByErrorToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByThanhVienIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucToaDoByActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucToaDoByActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucToaDoByActive_DanhMucTinhThanhID_SearchStringToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucToaDoByActive_DanhMucTinhThanhID_SearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucToaDoByDanhMucTinhThanhID_SearchStringToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucToaDoByDanhMucTinhThanhID_SearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucToaDoByIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucToaDoByIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

